import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout pageInfo={{ pageName: "contact" }}>
    <SEO title="Contact" />
    <h1 className="heading-4">Kontakt</h1>
    <p className="content-text">Bitte melden Sie sich per eMail oder Telefon bei uns: <br/>
      eMail: <a href="mailto:info@catcruise.de">info@catcruise.de</a><br/>
      Mobil: +491782550985<br/>
    </p>

  </Layout>
)

export default ContactPage
